import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import warnIcon from '../../../../../assets/images/icons/confirm-modal.svg';
import {useAuthedDeletion} from '../../../../common/hooks/QueryHooks';

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	deleteId: number | undefined;
	refetch: () => void;
	setSuccess: (success: boolean) => void;
};

const DeleteModal = ({open, deleteId, setOpen, refetch, setSuccess}: Props) => {
	const {isLoading, doDelete} = useAuthedDeletion(`/applications/${deleteId}`);
	const [error, setError] = useState<string | undefined>(undefined);

	return (
		<Modal aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={open}>
			<div className='modal-body scroll-y text-center'>
				<div className='container pt-4'>
					<div className='mb-12'>
						<SVG src={warnIcon} />
					</div>

					<div className='fs-1 fw-semibold' style={{color: '#3F4254'}}>
						Are you sure?
					</div>
					{error && (
						<p style={{fontWeight: 700}} className='text-danger text-bold mt-2'>
							{error}
						</p>
					)}
				</div>
				<div className='modal-footer justify-content-center border-top-0'>
					<button
						type='button'
						className='btn px-5 py-3 fw-semibold fs-4 rounded-3 mx-4'
						onClick={() => {
							setError('');
							setOpen(false);
						}}
						style={{
							border: '1px solid rgb(0, 149, 232)',
							color: 'rgb(0, 149, 232)',
							background: 'rgb(255, 255, 255)',
						}}
					>
						No
					</button>
					<button
						type='button'
						className='btn px-5 py-3 fw-semibold fs-4 rounded-3'
						style={{
							background: 'red',
							color: '#fff',
						}}
						onClick={async () => {
							try {
								await doDelete({});
								refetch();
								setOpen(false);
								setSuccess(true);
							} catch (e: any) {
								if (e) {
									setError('You do not have permission.');
								}
							}
						}}
					>
						<span>Yes! Delete</span>
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteModal;
