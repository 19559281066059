import {Modal} from 'react-bootstrap';
import {useRef, useState} from 'react';
import uploadImg from '../../../../../assets/images/icons/uploadPdf.svg';
import demoFile from '../../../../../assets/images/icons/uploadExcel.png';
import './DropFile.css';

type Props = {
	show: boolean;
	selectedFile: any;
	isUploading: boolean;
	setUploadModal: (success: boolean) => void;
	handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleButtonClick: () => void;
	setSelectedFile: (file: File | null) => void;
};

const UploadModal = ({
	show,
	selectedFile,
	isUploading,
	setUploadModal,
	handleFileChange,
	handleButtonClick,
	setSelectedFile,
}: Props) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
	const onDragEnter = () => {
		if (wrapperRef.current) {
			wrapperRef.current.classList.add('dragover');
		}
	};

	const onDragLeave = () => {
		if (wrapperRef.current) {
			wrapperRef?.current.classList.remove('dragover');
		}
	};

	const onDrop = () => {
		if (wrapperRef.current) {
			wrapperRef?.current.classList.remove('dragover');
		}
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		setErrorMsg(undefined);
		if (newFile) {
			if (!newFile.name.includes('.xlsx') || !newFile.name.includes('.xls')) {
				setErrorMsg('Only .xlsx file is accepted.');
				return;
			}
			handleFileChange(e);
		}
	};

	return (
		<Modal
			aria-hidden='true'
			onHide={() => setUploadModal(false)}
			dialogClassName='modal-dialog modal-dialog-centered'
			show={show}
		>
			<Modal.Header closeButton>
				<Modal.Title>Upload Excel File</Modal.Title>
			</Modal.Header>
			<div className='modal-body scroll-y text-center d-flex flex-column align-items-center' style={{minHeight: 250}}>
				<div className='w-100'>
					<div
						ref={wrapperRef}
						className='drop-file-input'
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						onDrop={onDrop}
					>
						<div className='drop-file-input__label'>
							<img src={uploadImg} alt='' />
							<p style={{fontSize: '16px'}}>Upload or Drag & Drop your file here</p>
						</div>
						<input type='file' accept='.xls, .xlsx' value='' onChange={onFileDrop} />
					</div>
					{errorMsg && (
						<p className='text-danger text-center my-2' style={{fontWeight: 500, fontSize: 16}}>
							{errorMsg}
						</p>
					)}
					{selectedFile && (
						<div className='d-flex flex-column align-items-center justify-content-center gap-5 mt-3'>
							<div className='position-relative mt-5 shadow-sm' style={{width: '400px'}}>
								<div
									style={{paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px'}}
									className='d-flex align-items-center bg-white rounded  position-relative text-break'
								>
									<img width={60} src={demoFile} alt='' />
									<p className='mb-0'>{selectedFile?.name}</p>
								</div>
								<button
									style={{paddingLeft: 8, paddingRight: 8, top: '-12px', right: '-8px'}}
									className='border-0 position-absolute bg-danger  text-white  rounded-circle'
									onClick={() => setSelectedFile(null)}
								>
									x
								</button>
							</div>

							<button
								onClick={handleButtonClick}
								className='btn btn-danger d-flex mt-1 align-items-center'
								type='submit'
							>
								{!isUploading && <span>Upload</span>}
								{isUploading && (
									<span className='indicator-progress' style={{display: 'block'}}>
										Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
									</span>
								)}
							</button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default UploadModal;
