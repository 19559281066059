import {StatusCountOfStatus} from '../../pages/application-list/Queuetypes';

interface Props {
	title: string;
	titleClass: string;
	totalClass: string;
	statusCounts: StatusCountOfStatus | undefined;
}

export default function ApplicationStatCard({title, titleClass, totalClass, statusCounts}: Props) {
	return (
		<div className='bg-white' style={{padding: '8px', borderRadius: '8px'}}>
			<div className='row'>
				<div className='col-md-4'>
					<p className={titleClass}>{title}</p>
					<p className={totalClass}>{statusCounts?.total ?? 0}</p>
				</div>
				<div className='col-md-8' style={{lineHeight: '18px'}}>
					<div className='row'>
						<div className='col breakdownname' style={{paddingTop: '2px'}}>
							<p>PRA:</p>
							<p>Lite A:</p>
							<p>Lite B:</p>
							<p>Medium:</p>
						</div>
						<div className='col-md-2 breakdownnum'>
							<p>{statusCounts?.pra ?? 0}</p>
							<p>{statusCounts?.lite_a ?? 0}</p>
							<p>{statusCounts?.lite_b ?? 0}</p>
							<p>{statusCounts?.medium ?? 0}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
