import moment from 'moment';
import {Product, ProductName} from '../../../common/types/Products';

export enum RoleMode {
	QC = 'qc',
	Supervisor = 'supervisor',
	ECAD = 'ecad',
}

export interface UserInfo {
	id: number;
	name: string;
	role: string;
	email: string;
	profile_image: string;
	is_qc_1: boolean;
	is_qc_2: boolean;
	is_admin: boolean;
	is_ecad: boolean;
	is_supervisor: boolean;
	current_mode?: RoleMode;
	can_be_ready: boolean;
	last_ready_at: Date;
	can_change_role_mode: boolean;
	can_switch_product: boolean;
	current_product?: Product | null;
	assigned_products?: Array<Product> | null;
	can_access_cs_portal: boolean;
	can_read_dashboard: boolean;
	can_read_report: boolean;
	can_read_reasons?: boolean;
	can_access_posow_read: boolean;
	can_access_posow_upload: boolean;
	can_create_reasons?: boolean;
	can_delete_reasons?: boolean;
	can_read_application: boolean;
	can_read_risk_rating: boolean;
	can_read_all_applications: boolean;
	can_read_application_history: boolean;
	can_update_application: boolean;
	can_update_configuration: boolean;
	can_read_role: boolean;
	can_create_role: boolean;
	can_update_role: boolean;
	can_read_user: boolean;
	can_create_user: boolean;
	can_update_user: boolean;
	can_delete_user: boolean;
	can_delete_application: boolean;
	can_read_log: boolean;
	can_read_configuration: boolean;
	can_write_configuration: boolean;
	can_read_queue_application: boolean;
	can_read_assigned_applications: boolean;
	can_retry_cps_request: boolean;
	can_read_cps_configuration: boolean;
	can_update_cps_configuration: boolean;
	can_access_bulk_pdf_download: boolean;
	can_access_bulk_pdf_upload: boolean;
}

export interface UserModel {
	token: string;
	isReady: boolean;
	info: UserInfo;
	email?: string;
}

export const userHelpers = {
	offlineTimerInMinute: 0.03,
	isAdmin: (user: UserInfo) => {
		return user.is_admin;
	},
	isSupervisor: (user: UserInfo) => {
		return user.is_supervisor;
	},
	isQc1: (user: UserInfo) => {
		return user.is_qc_1;
	},
	isQc2: (user: UserInfo) => {
		return user.is_qc_2;
	},
	isQc: (user: UserInfo) => {
		return userHelpers.isQc1(user) || userHelpers.isQc2(user);
	},
	isEcad: (user: UserInfo) => {
		return user.is_ecad;
	},
	isInSupervisorMode: (user: UserInfo) => {
		return user.current_mode === RoleMode.Supervisor;
	},
	isInEcadMode: (user: UserInfo) => {
		return user.current_mode === RoleMode.ECAD;
	},
	isInQcMode: (user: UserInfo) => {
		return user.current_mode === RoleMode.QC;
	},
	getToggleRoleModeTargetForSupervisor: (user: UserInfo) => {
		return userHelpers.isInSupervisorMode(user) ? RoleMode.QC : RoleMode.Supervisor;
	},
	getToggleRoleModeTargetForECAD: (user: UserInfo) => {
		return userHelpers.isInEcadMode(user) ? RoleMode.QC : RoleMode.ECAD;
	},
	getToggleRoleModeTarget: (user: UserInfo) => {
		return userHelpers.isSupervisor(user)
			? userHelpers.getToggleRoleModeTargetForSupervisor(user)
			: userHelpers.getToggleRoleModeTargetForECAD(user);
	},
	canChangeRoleMode: (user: UserInfo) => {
		return user.can_change_role_mode;
	},
	canBeReady: (user: UserInfo) => {
		return user.can_be_ready;
	},
	canSwitchProduct: (user: UserInfo) => {
		return user.can_switch_product;
	},
	isOfflineForTooLong: (user: UserInfo) => {
		return moment.duration(moment().diff(moment(user.last_ready_at))).asMinutes() > userHelpers.offlineTimerInMinute;
	},
	canTakeActionInApplication: (user: UserInfo) => {
		return userHelpers.isQc(user) || userHelpers.isEcad(user) || userHelpers.isSupervisor(user);
	},
	visibleAcceptAll: (user: UserInfo) => {
		return userHelpers.isQc(user) || (userHelpers.isSupervisor(user) && userHelpers.isInQcMode(user));
	},
	hasProductAssigned: (user: UserInfo, product: ProductName) => {
		if (!user.assigned_products) return true;

		return user.assigned_products.filter((p) => p.name === product).length > 0;
	},
	hasAssignedPRA: (user: UserInfo) => userHelpers.hasProductAssigned(user, ProductName.pra),
	hasAssignedLiteA: (user: UserInfo) => userHelpers.hasProductAssigned(user, ProductName.liteA),
	hasAssignedLiteB: (user: UserInfo) => userHelpers.hasProductAssigned(user, ProductName.liteB),
	hasAssignedMedium: (user: UserInfo) => userHelpers.hasProductAssigned(user, ProductName.medium),
};
