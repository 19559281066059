import {useAuthedQuery} from '../../../../common/hooks/QueryHooks';
import {StatusCountsWrapper} from '../../pages/application-list/Queuetypes';

const useApplicationStats = () => {
	const {data, isLoading} = useAuthedQuery<StatusCountsWrapper>('application-stats', '/applications/stats-by-status');

	return {
		data,
		isLoading,
	};
};

export default useApplicationStats;
