import axios, {AxiosError, AxiosResponse} from 'axios';

export type HttpHeaders = {[key: string]: string};

const addDefaultHeaders = (header: HttpHeaders) => {
	const headers = {...header};
	headers['Content-type'] = 'application/json; charset=UTF-8';
	headers['User-Agent'] = window.navigator.userAgent;
	return headers;
};

export function addAuthHeader(headers: HttpHeaders | undefined, token: string | undefined): HttpHeaders {
	const headersWithAuth = {...(headers ?? {})};
	headersWithAuth.Authorization = `Bearer ${token ?? ''}`;
	return headersWithAuth;
}

export async function getHttp<T>(uri: string, header: HttpHeaders = {}): Promise<T> {
	const res = await axios.get<T>(uri, {
		headers: addDefaultHeaders(header),
	});
	return res.data;
}

export async function postHttp<T>(uri: string, body: object, header: HttpHeaders = {}): Promise<T> {
	const res = await axios.post<T>(uri, body, {
		headers: addDefaultHeaders(header),
	});
	return res.data;
}

export async function deleteHttp<T>(uri: string, header: HttpHeaders = {}): Promise<T> {
	const res = await axios.delete<T>(uri, {
		headers: addDefaultHeaders(header),
	});
	return res.data;
}

export async function putHttp<T>(uri: string, body: object, header: HttpHeaders = {}): Promise<T> {
	const res = await axios.put<T>(uri, body, {
		headers: addDefaultHeaders(header),
	});
	return res.data;
}

export async function patchHttp<T>(uri: string, body: object, header: HttpHeaders = {}): Promise<T> {
	const res = await axios.patch<T>(uri, body, {
		headers: addDefaultHeaders(header),
	});
	return res.data;
}

function isHttpError(error: unknown) {
	return typeof error === 'object' && error && 'response' in error;
}

export function isUnauthorizedResponse(response: AxiosResponse | undefined) {
	return response?.status === 401;
}

export function isUnauthorizedHttpError(error: unknown) {
	if (!isHttpError(error)) return false;

	return isUnauthorizedResponse((error as AxiosError)?.response);
}
