import {useCallback, useEffect, useState} from 'react';
import {useAuthedQuery} from '../../../../../common/hooks/QueryHooks';
import {Product, Status, QueryTypes, QueryTypesDropDown} from '../Queuetypes';

const useApplicationSearch = () => {
	const {data: productsData} = useAuthedQuery<Product>('applications/products', 'applications/product-type');
	const {data: statusData} = useAuthedQuery<Status>('applications/statuses', 'applications/statuses');
	const {data: mqcStatusData} = useAuthedQuery<Status>('applications/mqc-statuses', 'applications/mqc-statuses');
	const {data: queryTypesData} = useAuthedQuery<QueryTypes>('applications/query-type', 'applications/query-type');

	const [currentQueryType, setCurrentQueryType] = useState<QueryTypesDropDown | null>(null);

	useEffect(() => {
		if (!queryTypesData) return;
		if (queryTypesData.data.length === 0) return;
		setCurrentQueryType(queryTypesData.data[2]);
	}, [queryTypesData]);

	const changeCurrentQueryType = useCallback(
		(value: string) => {
			if (!queryTypesData) return;
			queryTypesData.data.forEach((v: QueryTypesDropDown) => {
				if (v.key === value) setCurrentQueryType(v);
			});
		},
		[queryTypesData],
	);

	return {productsData, statusData, mqcStatusData, queryTypesData, currentQueryType, changeCurrentQueryType};
};

export default useApplicationSearch;
