import {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import rightvec from '../../../../../assets/images/application/RightVector.svg';
import WhiteCard from '../../../../common/components/card/WhiteCard';
import DataTable from '../../../../common/components/data-table/DataTable';
import {useAuth} from '../../../auth';
import {userHelpers} from '../../../auth/common/Types';
import CardHeader from '../../../users/common/components/CardHeader';
import {Header, Row} from '../../pages/application-in-queue/Types';
import {
	Application,
	ApplicationStatus,
	ApplicationTableData,
	SortColumns,
	StatusType,
} from '../../pages/application-list/Queuetypes';
import DeleteModal from './DeleteModal';
import SuccessModal from './SuccessModal';
import './Tablesection.css';

interface Props {
	data: ApplicationTableData | Application[] | undefined;
	refetch: () => void;
	isLoading: boolean;
	setCurrentPage: (page: number) => void;
	changeItemsPerpage: (count: number) => void;
	sortColumns: SortColumns;
	sortColumn: (key: string) => void;
	downloadPdf: () => void;
	isPdfUpload: boolean;
	selectedFile: any;
	isUploading: boolean;
	fileInputRef: any;
	handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleButtonClick: () => void;
	setUploadModal: (open: boolean) => void;
	pdfLoading: boolean;
	ids: Array<number>;
	list: Array<number>;
	setList: (list: Array<number>) => void;
}

const PdfTablesection = ({
	data,
	refetch,
	isLoading,
	setCurrentPage,
	changeItemsPerpage,
	sortColumns,
	sortColumn,
	downloadPdf,
	fileInputRef,
	handleFileChange,
	handleButtonClick,
	setUploadModal,
	isPdfUpload,
	pdfLoading,
	ids,
	list,
	isUploading,
	selectedFile,
	setList,
}: Props) => {
	const {currentUserInfo} = useAuth();
	const [isRefreshLoading, setIsRefreshLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
	const [success, setSuccess] = useState<boolean>(false);

	const isQc = currentUserInfo ? userHelpers.isQc(currentUserInfo) : false;
	const isQcPool = userHelpers.isQc(currentUserInfo!);
	const canDelete = currentUserInfo?.can_delete_application;
	const location = useLocation();
	const assignedTome = () => {
		return location.pathname === '/applications/assigned-to-me' || location.pathname === '/applications/history';
	};

	const reFresh = async () => {
		setIsRefreshLoading(true);
		await refetch();
		setIsRefreshLoading(false);
	};

	const checkStatusName = (name: string) => {
		return ['Force Rejected', 'Succeed', 'Denied'].includes(name);
	};

	const headers: Array<Header> = [
		{
			text: '',
			isSortable: false,
		},
		{
			text: 'ID',
			isSortable: true,
			sortOrder: sortColumns.id,
			sortKey: 'id',
		},
		{
			text: 'Application On',
			isSortable: true,
			sortOrder: sortColumns.created_at,
			sortKey: 'created_at',
		},
		{
			text: 'Wallet Number',
			isSortable: false,
		},
		{
			text: 'Product',
			isSortable: true,
			sortOrder: sortColumns.product,
			sortKey: 'product',
		},
		{
			text: 'NID',
			isSortable: false,
		},
		{
			text: 'Agent Number',
			isSortable: false,
		},
		{
			text: 'Status',
			isSortable: true,
			sortOrder: sortColumns.status,
			sortKey: 'status',
		},
	];

	if (!isQc) {
		headers.push({
			text: 'MQC Status',
			isSortable: false,
		});
	}

	const getStatusStyle = (status: StatusType) => {
		if (status.name === ApplicationStatus.MQC_Failed) return {bg_color: '#F18041', color: '#FFFFFF'};
		return status;
	};

	const rows: Array<Row> = (isPdfUpload ? data : (data as any)?.data || []).map((application: Application) => {
		const itemsArray = [
			application.created_at,
			application.wallet_number,
			<span
				key={application.id}
				className='fs-8 py-2 px-2 rounded-1'
				style={{color: application.product.color, backgroundColor: application.product.bg_color}}
			>
				{application.product.name}{' '}
			</span>,
			application.nid,
			application.agent_number,

			<span
				key={application.id}
				className='fs-8 py-2  rounded-1 s-status'
				style={{
					color: getStatusStyle(application.status).color,
					backgroundColor: getStatusStyle(application.status).bg_color,
				}}
			>
				{application.status.name}
			</span>,
		];

		if (application.mqc_status?.name.toLowerCase() === 'pending') {
			itemsArray.unshift(application.displayable_id);
		}

		if (application.mqc_status?.name.toLowerCase() !== 'pending') {
			itemsArray.unshift(
				<Link to={`/applications/${application.id}`} key={application.id} style={{color: '#000000b8'}}>
					{application.displayable_id}
				</Link>,
			);
		}
		if (!isQc) {
			itemsArray.push(
				<span
					key={application.id}
					className='fs-8 py-2 px-1  rounded-1'
					style={{
						color: application.mqc_status?.color,
						backgroundColor: application.mqc_status?.bg_color,
					}}
				>
					{application.mqc_status?.name}{' '}
				</span>,
			);
		}

		itemsArray.unshift(
			<label className='form-check checkbox'>
				<input
					type='checkbox'
					onChange={(e) => {
						if (e.target.checked) {
							setList([...list, application.id]);
						} else {
							setList([...list.filter((id) => id !== application.id)]);
						}
					}}
					className='form-check-input'
					name='Checkboxes2'
					checked={list.includes(application.id)}
				/>
			</label>,
		);

		return {
			key: application.id,
			items: itemsArray,
		};
	});

	const hasData = isPdfUpload
		? ((data as Application[]) ?? []).length > 0
		: ((data as ApplicationTableData)?.data ?? []).length > 0;

	return (
		<WhiteCard
			leftHeader={
				<CardHeader
					title={`${
						isPdfUpload ? (data as Application[]).length : (data as ApplicationTableData)?.meta.total ?? 0
					} Applications`}
				/>
			}
			rightHeader={
				<div>
					<div className='d-flex justify-content-end align-items-center'>
						<button
							onClick={() => {
								setList([...list, ...ids]);
							}}
							className='btn  mt-2 btn-primary me-2'
						>
							Select All
						</button>
						{hasData && (
							<button className='btn  mt-2 btn-danger me-2' onClick={downloadPdf}>
								{pdfLoading ? (
									<span className='indicator-progress' style={{display: 'block'}}>
										Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
									</span>
								) : (
									'Download'
								)}
							</button>
						)}
						{isPdfUpload && (
							<button onClick={() => setUploadModal(true)} className='btn btn-warning mt-2'>
								Upload
							</button>
						)}
					</div>
				</div>
			}
			extraClasses='p-9'
		>
			<div className='application-list-table'>
				<DataTable
					headers={headers}
					isLoading={isRefreshLoading ? true : isLoading}
					data={rows}
					sortColumn={sortColumn}
					changeItemPerPage={changeItemsPerpage}
					meta={isPdfUpload ? undefined : (data as any)?.meta}
					links={isPdfUpload ? undefined : (data as any)?.links}
					setCurrentPage={setCurrentPage}
				/>
			</div>
			<DeleteModal open={open} setOpen={setOpen} refetch={refetch} deleteId={deleteId} setSuccess={setSuccess} />
			<SuccessModal success={success} setSuccess={setSuccess} />
		</WhiteCard>
	);
};

export default PdfTablesection;
