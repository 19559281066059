import {FC} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {PageTitle} from '../../common/providers/PageDataProvider';
import useNotifications from '../../common/hooks/useNotifications';
import WhiteCard from '../../common/components/card/WhiteCard';
import CardHeader from '../users/common/components/CardHeader';
import DataTable from '../../common/components/data-table/DataTable';
import {Header, Row} from './Types';
import {Notification, buildTargetUrl} from '../../common/types/Notifications';
import rightvec from '../../../assets/images/application/Rightvec.svg';
import {useAuth} from '../auth';

const Notifications: FC = () => {
	const {data, isLoading, setCurrentPage, changeItemPerPage} = useNotifications({initialItemPerPage: 10});

	const sortColumn = (column: string) => {};

	const headers: Array<Header> = [
		{
			text: 'Notification',
			isSortable: false,
		},
		{
			text: 'Date',
			isSortable: false,
		},
		{
			text: 'Action',
			isSortable: false,
		},
	];

	const {currentUser} = useAuth();

	const rows: Array<Row> = (data?.data || []).map((notification: Notification) => {
		return {
			key: notification.id,
			items: [
				notification.title,
				moment(notification.created_at).format('h:mm a, DD MMMM, YYYY'),
				<Link
					key={notification.id}
					to={`/${buildTargetUrl(notification, currentUser?.token)}`}
					className='btn btn-sm'
					style={{backgroundColor: '#F3F6F9'}}
				>
					<img src={rightvec} alt='button' />
				</Link>,
			],
		};
	});

	return (
		<>
			<PageTitle breadcrumbs={[]}>Notifications</PageTitle>

			<WhiteCard
				leftHeader={<CardHeader title='Latest Logs' />}
				rightHeader={<div className='mb-10' />}
				extraClasses='p-9'
			>
				<DataTable
					headers={headers}
					isLoading={isLoading}
					data={rows}
					sortColumn={sortColumn}
					changeItemPerPage={changeItemPerPage}
					meta={data?.meta}
					links={data?.links}
					setCurrentPage={setCurrentPage}
				/>
			</WhiteCard>
		</>
	);
};

export {Notifications};
