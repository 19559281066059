// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {useEffect} from 'react';

import DebouncedSearch from '../../../../common/components/DebouncedSearch';
import {useAuth} from '../../../auth';
import useApplicationSearch from '../../pages/application-list/hooks/useApplicationSearch';
import {Value} from '../../pages/application-list/Queuetypes';
import './Searchsection.css';

interface Props {
	setAccountType: (type: string) => void;
	setStatus: (type: string) => void;
	setMqcStatus: (status: string) => void;
	setDisputeListParam?: (status: string) => void;
	changeQuery: (queryType: string | null, queryValue: string) => void;
	setFormatType: (format: string) => void;
	handleSetPages: (page: string, checked: boolean) => void;
	// daterange: Array<Date>;
	// setDaterange: (dates: Array<Date>) => void;
	daterange: [Value, Value];
	setDaterange: (dates: [Value, Value]) => void;
	handleCustomerSearch?: () => void;
	isPdfUpload: boolean;
	formatType: string | null;
	pages: string[];
	isQc: boolean;
	isCPS?: boolean;
	isCustomer?: boolean;
}

const PdfSearchsection = ({
	setAccountType,
	setStatus,
	setMqcStatus,
	setDisputeListParam,
	setFormatType,
	changeQuery,
	daterange,
	setDaterange,
	handleCustomerSearch,
	handleSetPages,
	isPdfUpload,
	isQc,
	formatType,
	pages,
	isCPS = false,
	isCustomer = false,
}: Props) => {
	const {productsData, statusData, mqcStatusData, queryTypesData, currentQueryType, changeCurrentQueryType} =
		useApplicationSearch();
	const {currentUserInfo} = useAuth();

	const handleProductCategory = (e: any) => {
		setAccountType(e.target.value);
	};
	const handleStatusCategory = (e: any) => {
		setStatus(e.target.value);
	};
	const handleMqcStatusCategory = (e: any) => {
		setMqcStatus(e.target.value);
	};
	const handleDisputeList = (e: any) => {
		setDisputeListParam?.(e.target.value);
	};
	const handleQueryTypes = (e: any) => {
		changeCurrentQueryType(e.target.value);
	};

	const handleFormatType = (e: any) => {
		setFormatType(e.target.value);
	};

	useEffect(() => {
		(document?.getElementById('search-form') as HTMLFormElement)?.reset();
	}, [currentQueryType]);

	const infoPagesArray = [
		{key: 'personal_info', value: 'Personal Information'},
		{key: 'business_info', value: 'Business Information'},
		{key: 'facebook_page', value: 'Facebook Page'},
		{key: 'instagram_page', value: 'Instagram Page'},
		{key: 'website_page', value: 'Website Page'},
		{key: 'chequebook_page', value: 'Chequebook Page'},
		{key: 'trade_license_page', value: 'Trade License Page'},
		{key: 'vat_certificate_page', value: 'Vat Certificate Page'},
		{key: 'other_documents_page', value: 'Other Documents Page'},
		{key: 'terms_and_condition_page', value: 'Terms and Condition Page'},
	];

	return (
		<div className='row s-searchSection mb-13 flex-row d-flex g-0'>
			<div className='col-md-12  col-xl-7'>
				{!isPdfUpload && (
					<div className='d-flex flex-row'>
						<div>
							{!isCustomer && (
								<div className='calendarWrap'>
									<DateRangePicker
										dayPlaceholder='dd'
										monthPlaceholder='mm'
										yearPlaceholder='yyyy'
										calendarAriaLabel='Toggle calendar'
										clearAriaLabel='Clear value'
										dayAriaLabel='Day'
										monthAriaLabel='Month'
										nativeInputAriaLabel='Date'
										onChange={setDaterange}
										value={daterange}
										yearAriaLabel='Year'
										// clearIcon={null}
										minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
									/>
								</div>
							)}
						</div>
						<div className='mx-1'>
							{!isCustomer && (
								<select
									className='form-select form-select-solid'
									data-kt-select2='true'
									data-placeholder='Role'
									data-allow-clear='true'
									data-kt-user-table-filter='role'
									data-hide-search='true'
									style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
									onChange={handleProductCategory}
								>
									<option style={{color: '#A1A5B7'}} value=''>
										All Product
									</option>
									{productsData &&
										productsData?.data.map((po) => {
											return (
												<option value={po.key} key={po.key} style={{color: '#A1A5B7'}}>
													{po.value}
												</option>
											);
										})}
								</select>
							)}
						</div>
						{!isCPS && !isCustomer ? (
							<div className='mx-1'>
								<select
									className='form-select form-select-solid '
									data-kt-select2='true'
									data-placeholder='Role'
									data-allow-clear='true'
									data-kt-user-table-filter='role'
									data-hide-search='true'
									style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
									onChange={handleStatusCategory}
								>
									<option value='' style={{color: '#A1A5B7'}}>
										All Status
									</option>
									{statusData &&
										statusData?.data.map((sp) => (
											<option value={sp.key} key={sp.key} style={{color: '#A1A5B7'}}>
												{sp.value}
											</option>
										))}
								</select>
							</div>
						) : null}

						{!isQc && !isCustomer && (
							<div className='mx-1'>
								<select
									className='form-select form-select-solid '
									data-kt-select2='true'
									data-placeholder='Role'
									data-allow-clear='true'
									data-kt-user-table-filter='role'
									data-hide-search='true'
									style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
									onChange={handleMqcStatusCategory}
								>
									<option value='' style={{color: '#A1A5B7'}}>
										All MQC Status
									</option>
									{mqcStatusData &&
										mqcStatusData?.data.map((sp) => (
											<option value={sp.key} key={sp.key} style={{color: '#A1A5B7'}}>
												{sp.value}
											</option>
										))}
								</select>
							</div>
						)}
						{!isQc && !isCustomer && isCPS && (
							<div className='mx-1'>
								<select
									className='form-select form-select-solid '
									data-kt-select2='true'
									data-placeholder='Role'
									data-allow-clear='true'
									data-kt-user-table-filter='role'
									data-hide-search='true'
									style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
									onChange={handleDisputeList}
								>
									<option value='0' style={{color: '#A1A5B7'}}>
										Disputed List
									</option>
									<option value='1' style={{color: '#A1A5B7'}}>
										Already Exist
									</option>
								</select>
							</div>
						)}
					</div>
				)}

				<div className='mt-4 d-flex gap-5'>
					<div className='mx-1'>
						<select
							className='form-select form-select-solid '
							data-kt-select2='true'
							data-placeholder='Role'
							data-allow-clear='true'
							data-kt-user-table-filter='role'
							data-hide-search='true'
							style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
							onChange={handleFormatType}
						>
							<option selected={formatType === null} value='' style={{color: '#A1A5B7'}}>
								Select Format
							</option>
							<option value='bKash' selected={formatType === 'bKash'} style={{color: '#A1A5B7'}}>
								bKash
							</option>
						</select>
					</div>
					<div className='d-flex flex-row gap-5'>
						<div>
							{infoPagesArray.slice(0, 5).map((item, index) => (
								<label
									key={`pages_${item.key}`}
									className={`form-check checkbox ${index !== 0 && index !== 4 ? 'my-4' : null}`}
								>
									<input
										onChange={(e) => handleSetPages(item.key, e.target.checked)}
										type='checkbox'
										checked={pages.includes(item.key)}
										className='form-check-input'
										name='Checkboxes2'
									/>
									{item.value}
								</label>
							))}
						</div>
						<div>
							{infoPagesArray.slice(5).map((item, index) => (
								<label
									key={`pages_${item.key}`}
									className={`form-check checkbox ${index !== 0 && index !== 4 ? 'my-4' : null}`}
								>
									<input
										onChange={(e) => handleSetPages(item.key, e.target.checked)}
										type='checkbox'
										checked={pages.includes(item.key)}
										className='form-check-input'
										name='Checkboxes2'
									/>
									{item.value}
								</label>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className='col-md-12 col-xl-5 s-searchinput justify-content-xl-end'>
				<div className='d-flex margintopSearch align-items-center justify-content-end'>
					{isPdfUpload ? null : (
						<div className='d-flex'>
							<select
								className='form-select form-select-solid'
								data-kt-select2='true'
								data-placeholder='Role'
								data-allow-clear='true'
								data-kt-user-table-filter='role'
								data-hide-search='true'
								style={{backgroundColor: '#ffffff', borderRadius: '6px', width: 'auto'}}
								onChange={handleQueryTypes}
							>
								{queryTypesData &&
									queryTypesData?.data.map((po) => (
										<option
											selected={po.key === 'wallet_number'}
											value={po.key}
											key={po.key}
											style={{color: '#A1A5B7'}}
										>
											{po.value}
										</option>
									))}
							</select>
							<form id='search-form'>
								<div className='d-flex align-items-center position-relative'>
									<DebouncedSearch
										setSearchTerm={(searchTerm: string) => {
											changeQuery(currentQueryType?.key ?? null, searchTerm);
										}}
										placeholder={`Search by ${currentQueryType?.value}`}
									/>
								</div>
							</form>
						</div>
					)}

					{isCustomer ? (
						<div className='ms-2'>
							<button
								onClick={() => handleCustomerSearch?.()}
								className='btn btn-danger px-5 py-2 fw-semibold fs-4 rounded-3'
							>
								Search
							</button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default PdfSearchsection;
