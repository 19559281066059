import {Modal} from 'react-bootstrap';
import approved from '../../../../../assets/images/application/approved.svg';

type Props = {
	success: boolean;
	setSuccess: (success: boolean) => void;
};

const SuccessModal = ({success, setSuccess}: Props) => {
	return (
		<Modal aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={success}>
			<div className='modal-body scroll-y text-center'>
				<div className='container pt-4'>
					<div className='mb-12'>
						<img width={80} src={approved} alt='' />
					</div>

					<div className='fs-1 fw-semibold' style={{color: '#3F4254'}}>
						Successfully deleted.
					</div>
				</div>
				<div className='modal-footer justify-content-center border-top-0'>
					<button
						type='button'
						className='btn px-5 py-3 fw-semibold fs-4 rounded-3 mx-4'
						onClick={() => {
							setSuccess(false);
						}}
						style={{
							border: '1px solid rgb(0, 149, 232)',
							color: 'rgb(0, 149, 232)',
							background: 'rgb(255, 255, 255)',
						}}
					>
						Close
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default SuccessModal;
