import {SortOrder} from '../../../../common/components/data-table/Types';

export enum ApplicationStatus {
	MQC_Failed = 'MQC Failed',
}

export type StatusType = {
	bg_color: string;
	color: string;
	name: string;
};

export interface Application {
	id: number;
	displayable_id: string;
	created_at: string;
	wallet_number: string;
	product: {
		name: string;
		color: string;
		bg_color: string;
	};
	nid: string;
	agent_number: null | string;
	assigned_duration: string | null;
	risk_rating: {
		name: string;
		color: string;
		bg_color: string;
	};
	status: StatusType;
	mqc_status?: {
		name: string;
		color: string;
		bg_color: string;
	};
	queue_no?: {
		name: string;
		color: string;
		bg_color: string;
	};
	assigned_to_name: string;
	has_resubmitted: boolean;
}

export interface ProductDropDown {
	key: string;
	value: string;
}
export interface StatusDropDown {
	key: string;
	value: string;
}
export interface Status {
	data: Array<StatusDropDown>;
}
export interface Product {
	data: Array<ProductDropDown>;
}
export interface QueryTypesDropDown {
	key: string;
	value: string;
}
export interface QueryTypes {
	data: Array<QueryTypesDropDown>;
}

export interface ApplicationPaginationMeta {
	current_page: number;
	last_page: number;
	per_page: number;
	total: number;
}

export interface ApplicationTableData {
	data: Array<Application>;
	links: {
		first: string;
		last: string;
		prev: string | null;
		next: string | null;
	};
	meta: ApplicationPaginationMeta;
	// account_type: Products;
}

export interface SortColumns {
	id?: SortOrder;
	status?: SortOrder;
	product?: SortOrder;
	created_at?: SortOrder;
}

export interface StatusCountOfStatus {
	total: number;
	pra: number;
	lite_a: number;
	medium: number;
	lite_b: number;
}

export interface StatusCounts {
	in_queue: StatusCountOfStatus;
	accepted: StatusCountOfStatus;
	rejected: StatusCountOfStatus;
	escalated: StatusCountOfStatus;
}

export interface StatusCountsWrapper {
	status_counts: StatusCounts;
}

export interface ForceReject {
	message: string;
}

export type Value = Date | null;
