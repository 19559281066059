import useApplicationStats from '../hooks/useApplicationStats';
import ApplicationStatCard from './ApplicationStatCard';

export default function ApplicationStats() {
	const {data} = useApplicationStats();
	return (
		<div className='row mb-8'>
			<div className='col-md-3 '>
				<ApplicationStatCard
					title='Queue'
					titleClass='queue'
					totalClass='h-num'
					statusCounts={data?.status_counts.in_queue}
				/>
			</div>

			<div className='col-md-3 '>
				<ApplicationStatCard
					title='Accepted'
					titleClass='accepted'
					totalClass='h-numtwo'
					statusCounts={data?.status_counts.accepted}
				/>
			</div>

			<div className='col-md-3 '>
				<ApplicationStatCard
					title='Rejected'
					titleClass='rejected'
					totalClass='h-numthree'
					statusCounts={data?.status_counts.rejected}
				/>
			</div>

			<div className='col-md-3 '>
				<ApplicationStatCard
					title='Escalated'
					titleClass='escalated'
					totalClass='h-numfour'
					statusCounts={data?.status_counts.escalated}
				/>
			</div>
		</div>
	);
}
