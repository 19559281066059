import moment from 'moment';
import {Link} from 'react-router-dom';
import {abbreviate} from '../../../helpers';
import {buildTargetUrl, NotificationList} from '../../../types/Notifications';
import {useAuth} from '../../../../modules/auth';

interface Props {
	data: NotificationList | undefined;
	onNotificationClick: (key: string) => void;
}

const Notifications = ({data, onNotificationClick}: Props) => {
	const {currentUser} = useAuth();

	return (
		<div
			className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-375px'
			data-kt-menu='true'
		>
			<div className='menu-item px-3 my-0'>
				<div className='d-flex justify-content-between px-3 py-2'>
					<div className='fs-3 fw-bold mt-1' style={{color: '#3F4254'}}>
						Notifications
					</div>
					<div>
						<a className='btn py-2 px-4' href='/notifications' style={{backgroundColor: '#F3F6F9', color: '#464E5F'}}>
							View All
						</a>
					</div>
				</div>
			</div>

			{data &&
				data.data.map((notification) => (
					<div key={notification.id} className='menu-item px-3 my-0'>
						{notification.target_type === 'pdf_download' ? (
							<a
								href={buildTargetUrl(notification, currentUser?.token)}
								className='menu-link px-3 py-2'
								style={notification.is_read ? {} : {backgroundColor: '#F1EFEF'}}
								onClick={() => {
									// window.open(buildTargetUrl(notification, currentUser?.token), '_blank', 'noreferrer');
									onNotificationClick(notification.id);
								}}
							>
								<div className='d-flex align-items-center'>
									<div className='rounded w-25px h-25px' style={{backgroundColor: '#ECF8FF'}} />
									<div className='ms-4'>
										<div className='fw-bold fs-6 mb-2' style={{color: '#3F4254'}}>
											{/* {abbreviate(notification.title)} */}
											{notification.title}
										</div>
										<div className='fw-semibold fs-7' style={{color: '#B5B5C3'}}>
											{moment(notification.created_at).format('h:mm a, DD MMMM, YYYY')}
										</div>
									</div>
								</div>
							</a>
						) : (
							<Link
								to={buildTargetUrl(notification, currentUser?.token)}
								className='menu-link px-3 py-2'
								style={notification.is_read ? {} : {backgroundColor: '#F1EFEF'}}
								onClick={() => {
									onNotificationClick(notification.id);
								}}
							>
								<div className='d-flex align-items-center'>
									<div className='rounded w-25px h-25px' style={{backgroundColor: '#ECF8FF'}} />
									<div className='ms-4'>
										<div className='fw-bold fs-6 mb-2' style={{color: '#3F4254'}}>
											{/* {abbreviate(notification.title)} */}
											{notification.title}
										</div>
										<div className='fw-semibold fs-7' style={{color: '#B5B5C3'}}>
											{moment(notification.created_at).format('h:mm a, DD MMMM, YYYY')}
										</div>
									</div>
								</div>
							</Link>
						)}
					</div>
				))}

			{(!data || data.data.length === 0) && (
				<div className='menu-item px-3 my-0 text-center mt-5'>You do not have any notifications.</div>
			)}
		</div>
	);
};

export default Notifications;
